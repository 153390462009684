import React from 'react'
import {
  chain,
  Flex,
  Text,
  Token,
  useTooltip,
  VStack,
  Tooltip as UIKitTooltip,
} from '@revolut/ui-kit'
import {
  CalculatedDeliverablesRatingsInterface,
  PerformanceRating,
  Ratings,
  ReviewerRelationToShortTitle,
  ReviewSummaryDataInterface,
  SkillSummary,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  kpiGenericNameColumn,
  kpiPerformanceColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import {
  performanceSummaryCalculatedRatingColumn,
  performanceSummaryDeliverablesNameColumn,
  performanceSummaryExpectedCalcRatingColumn,
  performanceSummaryExpectedRatingColumn,
  performanceSummaryRatingColumn,
  performanceSummaryRecommendedRatingColumn,
  performanceSummarySkillsNameColumn,
} from '@src/constants/columns/performanceSummary'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { tooltipRatingToColor } from '@src/utils/performance'

interface Props {
  data: ReviewSummaryDataInterface
  completedReviews?: boolean
}

export interface KpiRowInterface extends KpiInterface {
  rating_expectation: PerformanceRating
  recommended_rating: PerformanceRating
}

const RatingWithTooltip = ({
  rating,
  ratings,
}: {
  rating?: PerformanceRating
  ratings?: Ratings[]
}) => {
  const ratingTooltip = useTooltip()
  if (!rating) {
    return <Text variant="h6">-</Text>
  }
  return (
    <Flex
      gap="s-8"
      {...ratingTooltip.getAnchorProps()}
      width="fit-content"
      style={{ cursor: 'pointer' }}
    >
      <Text variant="caption" textDecoration="underline">
        {PerformanceRatingTitle[rating]}
      </Text>
      <UIKitTooltip {...ratingTooltip.getTargetProps()} placement="top">
        <VStack>
          {ratings?.map(item => (
            <Flex key={item.review.id}>
              {chain(
                <Text variant="h6" color={tooltipRatingToColor(item.value)}>
                  {PerformanceRatingTitle[item.value]}
                </Text>,
                <Text>{item.review.reviewer.full_name}</Text>,
                <Text color={Token.color.greyTone50}>
                  {ReviewerRelationToShortTitle[item.review.reviewer_relation]}
                </Text>,
              )}
            </Flex>
          ))}
        </VStack>
      </UIKitTooltip>
    </Flex>
  )
}

const getRatingColumnValue = (
  data: SkillSummary | SummarySkillCardInterface | KpiRowInterface,
  completedReviews: boolean,
  isKpi?: boolean,
) => {
  if (!completedReviews) {
    const rating =
      isKpi && 'recommended_rating' in data ? data.recommended_rating : data.rating
    return rating ? PerformanceRatingTitle[rating] : '-'
  }
  return <RatingWithTooltip rating={data.rating || undefined} ratings={data.ratings} />
}

const getCalcRatingColumnValue = (
  data: CalculatedDeliverablesRatingsInterface,
  completedReviews: boolean,
) => {
  const rating = data.recommended_rating
  if (!completedReviews) {
    return rating ? PerformanceRatingTitle[rating as PerformanceRating] : '-'
  }
  return <RatingWithTooltip rating={rating as PerformanceRating} />
}

const getDeliverablesRow: (
  completedReviews: boolean,
) => RowInterface<SkillSummary> = completedReviews => ({
  cells: [
    {
      ...performanceSummaryDeliverablesNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) => getRatingColumnValue(data, completedReviews),
      width: 60,
    },
    {
      ...performanceSummaryExpectedRatingColumn,
      width: 60,
    },
  ],
})

const getCalcDeliverablesRow: (
  completedReviews: boolean,
) => RowInterface<CalculatedDeliverablesRatingsInterface> = completedReviews => ({
  cells: [
    {
      ...performanceSummaryDeliverablesNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryCalculatedRatingColumn,
      insert: ({ data }) => getCalcRatingColumnValue(data, completedReviews),
      width: 60,
    },
    {
      ...performanceSummaryExpectedCalcRatingColumn,
      width: 60,
    },
  ],
})

const getKpisRow: (
  completedReviews: boolean,
) => RowInterface<KpiRowInterface> = completedReviews => ({
  cells: [
    {
      ...kpiGenericNameColumn,
      sortKey: null,
      filterKey: null,
      width: 150,
    },
    {
      ...kpiWeightColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...performanceSummaryRecommendedRatingColumn,
      insert: ({ data }) => getRatingColumnValue(data, completedReviews, true),
      width: 60,
    },
    {
      ...performanceSummaryExpectedRatingColumn,
      width: 60,
    },
  ],
})

const getSkillsRow: (
  completedReviews: boolean,
) => RowInterface<SummarySkillCardInterface> = completedReviews => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) => getRatingColumnValue(data, completedReviews),
      width: 60,
    },
    {
      ...performanceSummaryExpectedRatingColumn,
      width: 60,
    },
  ],
})

const getValuesRow: (
  completedReviews: boolean,
) => RowInterface<SummarySkillCardInterface> = completedReviews => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) => getRatingColumnValue(data, completedReviews),
      width: 60,
    },
  ],
})

const TableWrapper = ({
  children,
  headerTitle,
  headerStat,
  headerRating,
  completedReviews,
}: {
  children: React.ReactNode
  headerTitle: string
  headerStat?: number
  headerRating?: PerformanceRating
  completedReviews?: boolean
}) => {
  return (
    <VStack space="s-16">
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="h6" color={Token.color.greyTone50}>
          {chain(
            headerTitle,
            headerStat ? (
              <Text color={getPercentColor((headerStat || 0) * 100)}>
                {formatPercentage(headerStat || 0)}
              </Text>
            ) : undefined,
          )}
        </Text>
        {completedReviews && headerRating && (
          <Text variant="h6">{PerformanceRatingTitle[headerRating]}</Text>
        )}
      </Flex>
      <Flex style={{ position: 'relative' }} flex="1 0">
        {children}
      </Flex>
    </VStack>
  )
}

export const SummaryReviewTables = ({ data, completedReviews = false }: Props) => {
  const calcDeliverablesData = data.calculated_deliverables_ratings
    ? [data.calculated_deliverables_ratings]
    : undefined
  const deliverablesData = data.deliverables ? [data.deliverables] : undefined
  const kpisData = data.kpis_section?.kpi_items
    ? [
        ...data.kpis_section.kpi_items.map(
          item =>
            ({
              ...item,
              rating_expectation: data.kpis_section?.rating_expectation,
              recommended_rating: data.kpis_section?.recommended_rating,
            } as KpiRowInterface),
        ),
      ]
    : undefined
  const skillsData = data.functional_skills?.cards
  const managerSkillsData = data.manager_skills?.cards
  const valuesData = data.culture_values?.cards || data.culture_skills?.cards

  return (
    <VStack space="s-24">
      {calcDeliverablesData && (
        <TableWrapper
          headerTitle="Goals and deliverables"
          headerRating={
            data.calculated_deliverables_ratings?.recommended_rating as PerformanceRating
          }
          completedReviews={completedReviews}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryDeliverables}
            row={getCalcDeliverablesRow(completedReviews)}
            count={calcDeliverablesData.length}
            data={calcDeliverablesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {deliverablesData && (
        <TableWrapper
          headerTitle="Goals and deliverables"
          headerRating={data.deliverables?.rating}
          completedReviews={completedReviews}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryDeliverables}
            row={getDeliverablesRow(completedReviews)}
            count={deliverablesData.length}
            data={deliverablesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {kpisData && (
        <TableWrapper
          headerTitle="Goals and deliverables"
          headerStat={data.kpis_section?.performance}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryKPIs}
            row={getKpisRow(completedReviews)}
            count={kpisData.length}
            data={kpisData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {skillsData && (
        <TableWrapper headerTitle="Functional skills">
          <AdjustableTable
            name={TableNames.PerformanceSummarySkills}
            row={getSkillsRow(completedReviews)}
            count={skillsData.length}
            data={skillsData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {managerSkillsData && (
        <TableWrapper headerTitle="Manager skills">
          <AdjustableTable
            name={TableNames.PerformanceSummaryManagerSkills}
            row={getSkillsRow(completedReviews)}
            count={managerSkillsData.length}
            data={managerSkillsData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {valuesData && (
        <TableWrapper headerTitle="Values">
          <AdjustableTable
            name={TableNames.PerformanceSummaryValues}
            row={getValuesRow(completedReviews)}
            count={valuesData.length}
            data={valuesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
    </VStack>
  )
}
