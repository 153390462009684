import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import {
  CardContentTypes,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { SaveDraftButton } from '@src/pages/Forms/EmployeePerformanceLayout/SaveDraftButton'
import { SubmitButton } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'
import { PageActions } from '@components/Page/PageActions'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import { PerformanceEditSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/PerformanceEditSummary'
import { SkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/SkillsCard'
import { Skills } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Skills'
import { CultureValues } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/CultureValues'
import { CultureValuesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/CultureValuesCard'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { ProbationDeliverablesCard } from '@src/pages/Forms/ProbationLayout/Cards/ProbationDeliverablesCard'
import { ProbationDeliverables } from '@src/pages/Forms/ProbationLayout/Sections/ProbationDeliverables'
import { getRecommendationAndType } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { BarRaiser } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/BarRaiser'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { StyledSticky } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { useGetProbationMissingValues } from '@src/pages/Forms/ProbationLayout/utils'

const renderRightColumContent = (
  values: ReviewScorecardInterface,
  selectedField?: SelectedFieldInterface,
) => {
  if (!selectedField?.type) {
    return <PerformanceEditSummary />
  }
  const reviewData = values.review_data

  switch (selectedField.type) {
    case CardContentTypes.KPI:
      return <KPI reviewData={reviewData} />
    case CardContentTypes.DELIVERABLES:
      return (
        <ProbationDeliverables
          reviewData={reviewData}
          category={values.category}
          cycleId={values.performance_checkpoint?.cycle_id}
          checkpointNum={values.probation_checkpoint?.number}
        />
      )
    case CardContentTypes.SKILLS:
      return <Skills selectedField={selectedField} />
    case CardContentTypes.VALUES:
      return <CultureValues selectedField={selectedField} />
    default:
      return null
  }
}

export const ProbationContent = connect(() => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values } = form
  const [selectedField, setSelectedField] = useState<SelectedFieldInterface | undefined>()
  const [isHelpOpen, setHelpOpen] = useState<HelpTabs | undefined>()
  const [submitChecked, setSubmitChecked] = useState(false)
  const recommendationSection = getRecommendationAndType(values)
  const { missingRating, missingBarRaiser } = useGetProbationMissingValues(values)

  // opening first available card
  useEffect(() => {
    if (values.review_data.kpis_section) {
      setSelectedField({
        type: CardContentTypes.KPI,
        field: 'review_data.kpis_section',
      })
    } else {
      setSelectedField({
        type: CardContentTypes.DELIVERABLES,
        field: 'review_data.calculated_deliverables_ratings',
      })
    }
  }, [])

  const onHelpTooltipClick = (helpTab: HelpTabs) => {
    if (isHelpOpen) {
      setHelpOpen(undefined)
    }
    setHelpOpen(helpTab)
  }

  const beforeSubmitCheck = () => {
    setSubmitChecked(true)
    return !(missingRating || missingBarRaiser)
  }
  console.log(recommendationSection)

  return (
    <>
      <PerformanceHeader isHelpOpen={isHelpOpen} setHelpOpen={setHelpOpen} />
      <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
        <TwoColumnsLayout
          leftFlex={1.4}
          left={
            <VStack space="s-16">
              <ProbationDeliverablesCard
                selectedField={selectedField}
                setSelectedField={setSelectedField}
                onHelpClick={onHelpTooltipClick}
              />
              <SkillsCard
                selectedField={selectedField}
                setSelectedField={setSelectedField}
                onHelpClick={onHelpTooltipClick}
              />
              <CultureValuesCard
                selectedField={selectedField}
                setSelectedField={setSelectedField}
                onHelpClick={onHelpTooltipClick}
              />
              {recommendationSection && (
                <BarRaiser
                  isViewMode={false}
                  questions={
                    recommendationSection.recommendation.pip_recommendation ||
                    recommendationSection.recommendation.recommendation
                  }
                  type={recommendationSection.type}
                  field={recommendationSection.field}
                  showBeforeSubmitCheckError={submitChecked}
                />
              )}
              <Feedback
                recommendationData={recommendationSection?.recommendation}
                type={recommendationSection?.type}
                onFeedbackClick={() => setSelectedField(undefined)}
                showBeforeSubmitCheckError={submitChecked}
              />
              <PageActions maxWidth="100%">
                <Flex gap="s-8" justifyContent="center">
                  <SaveDraftButton />
                  <SubmitButton beforeSubmitCheck={beforeSubmitCheck} />
                </Flex>
              </PageActions>
            </VStack>
          }
          right={
            <StyledSticky top={70}>
              {renderRightColumContent(values, selectedField)}
            </StyledSticky>
          }
        />
      </Flex>
    </>
  )
})
