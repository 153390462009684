import React, { useState } from 'react'
import { ReviewCategory, ReviewScorecardInterface } from '@src/interfaces/performance'
import {
  getCleanValuesBeforeSave,
  getLastTabLocalStorageKey,
} from '@src/utils/performance'
import { Button, StatusPopup } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'
import { useGetNotCompletedReviews } from '@src/api/performanceReview'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import set from 'lodash/set'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface Props {
  beforeSubmitCheck?: () => boolean
}
export const SubmitButton = connect(({ beforeSubmitCheck }: Props) => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const [openNextReview, setOpenNextReview] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()
  const { values, submit, isSubmitting } = form
  const isCompleted = values.status === Statuses.completed
  const user = useSelector(selectUser)
  const reviews = useGetNotCompletedReviews(user.id)
  const reviewsList = reviews?.data?.results?.filter(
    review => review.status === Statuses.pending || review.status === Statuses.draft,
  )

  const getReviewUrl = () => {
    const currentReviewIndex = reviewsList?.findIndex(review => review.id === Number(id))
    let switchToReview
    if (currentReviewIndex !== undefined && currentReviewIndex !== -1) {
      switchToReview =
        reviewsList?.[currentReviewIndex + 1] || reviewsList?.[currentReviewIndex - 1]
    }
    if (switchToReview) {
      switch (switchToReview?.category) {
        case ReviewCategory.Upwards:
          return pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW.SKILLS, {
            employeeId: switchToReview.reviewed_employee.id,
            id: switchToReview.id,
          })
        case ReviewCategory.Performance:
          return pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT, {
            employeeId: switchToReview.reviewed_employee.id,
            id: switchToReview.id,
          })
        default:
          return pathToUrl(ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES, {
            employeeId: switchToReview.reviewed_employee.id,
            id: switchToReview.id,
          })
      }
    }

    return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
      id: employeeId,
    })
  }

  const clearLocalStorage = () => {
    workspaceLocalStorage.removeItem(
      getLastTabLocalStorageKey(values.category, employeeId, id),
    )
  }

  const handleSave = async () => {
    if (beforeSubmitCheck && !beforeSubmitCheck()) {
      return
    }

    form.values = getCleanValuesBeforeSave(values)
    const currentStatus = form.values.status
    form.values.status = Statuses.completed

    setLoading(true)
    try {
      const result = await submit()
      if (result.id) {
        setOpenNextReview(true)
      } else {
        clearLocalStorage()
        goBack(getReviewUrl())
      }
    } catch (error) {
      set(form.errors, 'review_data', error.response?.data?.review_data)
      form.values.status = currentStatus
    } finally {
      setLoading(false)
    }
  }

  const goNext = () => {
    setOpenNextReview(false)
    clearLocalStorage()
    navigateTo(getReviewUrl())
  }

  const pending = isSubmitting || loading
  const disabled =
    !!values.review_data.kpis_section &&
    !values.review_data.kpis_section.recommended_rating

  return (
    <>
      <StatusPopup variant="success" open={openNextReview} onClose={goNext}>
        <StatusPopup.Title>Feedback submitted</StatusPopup.Title>
      </StatusPopup>

      <Button
        onClick={handleSave}
        pending={pending && form.values.status !== Statuses.draft}
        disabled={pending || disabled}
        width={167}
        variant="default"
        elevated
        data-testid="btn-submit"
      >
        {isCompleted ? 'Save Changes' : 'Submit'}
      </Button>
    </>
  )
})
