import React, { useRef } from 'react'
import { ManagerRecommendationInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Avatar,
  Badge,
  Box,
  chain,
  FilterButton,
  Flex,
  SelectOptionItemType,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import { RecommendationType } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import styled from 'styled-components'
import { getReviewerRelation } from '@src/pages/Forms/EmployeePerformanceLayout/utils'

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: ${props => props.theme.zIndex.main};
  font-weight: 500;
  min-width: 16px;
  height: 16px;
  border: 2px solid ${Token.color.background};
  box-sizing: content-box;
  padding: 0 2px;
`

export interface Recommendation {
  recommendation: ManagerRecommendationInterface
  type: RecommendationType
}

export interface RecommendationProps {
  recommendations: Recommendation[]
}

const BarRaiserButton = ({
  option,
  reviews,
}: {
  option: SelectOptionItemType<OptionInterface>
  reviews: Recommendation[]
}) => {
  const tooltip = useTooltip()
  const active = !!reviews.length

  return (
    <FilterButton
      active={active}
      disabled={!reviews.length}
      style={{ height: '30px', position: 'relative' }}
      {...tooltip.getAnchorProps()}
    >
      {option.value.name}
      {active && (
        <Tooltip {...tooltip.getTargetProps()} maxWidth={200} placement="top">
          <VStack>
            {reviews.map(review => (
              <Box key={review.recommendation.reviewer?.id}>
                {chain(
                  <Text variant="small" color={Token.color.greyTone50}>
                    {getReviewerRelation(review.type)}
                  </Text>,
                  <Text variant="small">
                    {review.recommendation.reviewer?.full_name}
                  </Text>,
                )}
              </Box>
            ))}
          </VStack>
        </Tooltip>
      )}
      {reviews.length > 1 && (
        <StyledBadge backgroundColor={Token.color.deepGrey}>{reviews.length}</StyledBadge>
      )}
    </FilterButton>
  )
}

export const BarRaiserView = connect(({ recommendations }: RecommendationProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { options } = useFetchOptions<OptionInterface>(selectorKeys.yes_no_range_options)
  const firstRecommendationQuestions =
    recommendations[0]?.recommendation?.keeper_test_section?.questions ||
    recommendations[0]?.recommendation?.recommendation

  if (!firstRecommendationQuestions) {
    return null
  }

  return (
    <>
      <Widget p="s-16">
        <VStack space="s-32" ref={ref}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap="s-16" width="30%">
              <Avatar useIcon="HelpChat" />
              <Text variant="primary">Bar raiser</Text>
            </Flex>
          </Flex>
          {firstRecommendationQuestions?.map((question, num) => {
            return (
              <VStack space="s-16" key={num}>
                <Text variant="primary">{question.name || question.title}</Text>
                <Flex justifyContent="left" gap="s-8">
                  {options.map(option => {
                    const reviews = recommendations.filter(el => {
                      const questionValue =
                        el.recommendation.keeper_test_section?.questions[num].value ||
                        el.recommendation.recommendation?.[num]?.value
                      return questionValue === option.value.id
                    })
                    return (
                      <BarRaiserButton
                        key={option.value.id}
                        option={option}
                        reviews={reviews}
                      />
                    )
                  })}
                </Flex>
              </VStack>
            )
          })}
        </VStack>
      </Widget>
    </>
  )
})
